import React, { useEffect, useRef } from "react";
import lock from "./img/lock.svg";
import music from "./audio/background_music.mp3";

const DEVELOPMENT = process.env.NODE_ENV === "development";

function importAll(r) {
  return r.keys().map(r);
}

function Page1({ curPage }) {
  return (
    <div
      className={`absolute mx-5 my-3 space-y-2 font-bold text-white transition-opacity duration-1000 ease-in-out ${
        curPage === 1 ? "opacity-100" : "opacity-0"
      }`}
    >
      <p className="text-md text-start">Hey baby ♥</p>
      <p className="text-md text-start indent-10">
        Happy 3 months! That bouquet is only going to get bigger and bigger. I’m
        sorry this past month has been so turbulent, but regardless, I am
        constantly thankful for your presence in my life. During your highest
        highs and your lowest lows, I’ll be there by your side, as long as
        you’ll have me. There really is no one like you.
      </p>
      <p className="text-md text-start indent-10">
        I’ve never met anyone with such amazing range. Your ability to go from
        serious talks to making the most peculiar jokes is astonishing to me. It
        always feels like you know just the right things to say, and I’m
        constantly getting an ab workout with the way you make me laugh. I never
        thought someone like you existed. Someone so inspiring, eccentric,
        hilarious, and beautiful in so many different ways.
      </p>
    </div>
  );
}

function Page2({ curPage }) {
  return (
    <div
      className={`absolute mx-5 my-3 space-y-2 font-bold transition-opacity duration-1000 ease-in-out ${
        curPage === 2 ? "opacity-100" : "opacity-0"
      }`}
    >
      <p className="text-md text-start indent-10">
        You continue to surprise me everyday, and I’m always excited for the
        next one coming.
      </p>
      <p className="text-md text-start indent-10 text-white">
        When I look into your eyes, I feel at peace with myself. Like every
        terrible thing in the world - whether it be the past, the present, or
        the future - suddenly disappears and all that there is is being there
        with you. Although we haven’t been together long, you’ve taught me so
        many things. Through our love, I’ve learned that it’s okay to be my true
        self. I’ve bared my soul to you in a way that I’ve never done before,
        yet you still continue to look at me with gentle eyes. Holding your
        hand, holding you close, or simply seeing your smile: they’re the most
        beautiful experiences I’ve ever had. Even Paris can’t compare.
      </p>
    </div>
  );
}

function Page3({ curPage }) {
  return (
    <div
      className={`absolute mx-5 my-3 space-y-2 font-bold transition-opacity duration-1000 ease-in-out ${
        curPage === 3 ? "opacity-100" : "opacity-0"
      }`}
    >
      <p className="text-md text-start indent-10 text-white">
        Suddenly every love song makes sense. I can’t stop my eyes from looking
        at you and smiling at every reminder of your existence.
      </p>
      <p className="text-md ml-40 mt-6 text-start text-white">I love you,</p>
      <p className="text-md ml-40 text-start text-white">Kristina</p>
    </div>
  );
}

function Page4({ curPage }) {
  return (
    <div
      className={`absolute mx-5 my-3 space-y-2 font-bold text-white transition-opacity duration-1000 ease-in-out ${
        curPage === 4 ? "opacity-100" : "opacity-0"
      }`}
    ></div>
  );
}

function FlashImage({
  availableImages,
  delay,
  selectedImages,
  setSelectedImages,
  globalIndex,
  setGlobalIndex,
}) {
  const [selectedImage, setSelectedImage] = React.useState("");
  const [position, setPosition] = React.useState([0, 0, 0]);
  const [active, setActive] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [localIndex, setLocalIndex] = React.useState(0);

  const updatePosition = () => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    );
    const maxWidth = vw - 0.3 * vw;
    const maxHeight = vh - 0.2 * vh;
    setPosition([
      Math.floor(Math.random() * maxWidth),
      Math.floor(Math.random() * maxHeight),
      Math.floor(Math.random() * 120) - 60,
    ]);
  };

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, delay);
  }, [delay]);

  useEffect(() => {
    if (ready === false) {
      return;
    } else {
      if (selectedImages.length >= 5) {
        setSelectedImages([]);
        return;
      }
      let newSelectedImage =
        availableImages[Math.floor(Math.random() * availableImages.length)];
      while (selectedImages.includes(newSelectedImage)) {
        newSelectedImage =
          availableImages[Math.floor(Math.random() * availableImages.length)];
      }
      setSelectedImages([...selectedImages, newSelectedImage]);
      setSelectedImage(newSelectedImage);
      setReady(false);
      updatePosition();
      setActive(true);
      setTimeout(() => {
        setActive(false);
      }, 3000);
      setTimeout(
        () => {
          setReady(true);
        },
        5000 + Math.floor(Math.random() * 3000),
      );
      setLocalIndex(globalIndex);
      setGlobalIndex(globalIndex + 1);
    }
  }, [ready, delay, selectedImages]);

  return (
    <div
      className={`absolute h-fit w-fit bg-white p-2 pb-2 transition-opacity duration-[2000ms] ease-in-out ${
        active ? "opacity-100" : "opacity-0"
      }`}
      style={{
        left: `${position[0]}px`,
        top: `${position[1]}px`,
        transform: `rotate(${position[2]}deg)`,
        zIndex: `${localIndex}`,
      }}
    >
      <img src={selectedImage} alt="0" className={`aspect-auto h-48`} />
      <p className="text-center text-[0.5em] mt-2 font-bold text-black">
        (ɔ ˘⌣˘)˘⌣˘ c)♡
      </p>
    </div>
  );
}

function MainApp() {
  const [availableImages] = React.useState(
    importAll(
      require.context("./img/image_rotation", false, /\.(png|JPE?G|svg)$/),
    ),
  );
  const [page, setPage] = React.useState(1);
  const [loaded, setLoaded] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(true);
  const [selectedImages, setSelectedImages] = React.useState([]);
  const [globalIndex, setGlobalIndex] = React.useState(0);
  const audioRef = useRef(new Audio(music));

  useEffect(() => {
    setLoaded(true);
    audioRef.current.loop = true;
  });

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="h-full w-full">
      <div className="relative h-full w-full">
        {Array.from({ length: 5 }).map((_, index) => (
          <FlashImage
            availableImages={availableImages}
            delay={2000 + 2000 * index}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            globalIndex={globalIndex}
            setGlobalIndex={setGlobalIndex}
            key={index}
          />
        ))}
        <div
          className={`relative z-[99999999] flex h-full w-full flex-col items-center justify-center text-center italic text-white transition-opacity duration-[3000ms] ease-in-out ${
            loaded ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className={ `relative my-[2.5%] h-full w-[95%] rounded-3xl border-2 border-white leading-[1.4em] ${page !== 4 ? "backdrop-blur-[2px]" : null}` }>
            <Page1 curPage={page} />
            <Page2 curPage={page} />
            <Page3 curPage={page} />
            <Page4 curPage={page} />
            <div className="absolute bottom-3 flex w-full flex-row items-center justify-between px-3">
              <button
                className={`rounded-lg border border-white px-3 py-1 text-2xl font-bold transition-opacity ease-in focus:outline-none ${
                  page > 1 ? "opacity-100" : "opacity-0"
                }`}
                disabled={page <= 1}
                onClick={() => setPage(page - 1)}
              >
                Back
              </button>
              <button
                className={`rounded-lg border border-white px-3 py-1 text-2xl font-bold transition-opacity duration-500 ease-in focus:outline-none`}
                onClick={togglePlay}
              >
                {isPlaying ? "Mute" : "Play"}
              </button>
              <button
                className={`rounded-lg border border-white px-3 py-1 text-2xl font-bold transition-opacity ease-in focus:outline-none ${
                  page < 4 ? "opacity-100" : "opacity-0"
                }`}
                disabled={page >= 4}
                onClick={() => setPage(page + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function checkPassword(password, truePassword) {
  return password === truePassword;
}

function LockedByPassword({ password, setPassword, setUnlock, truePassword }) {
  const [failedAttempts, setFailedAttempts] = React.useState(0);

  return (
    <div className="flex h-full w-full flex-col items-center text-center backdrop-blur-[2px]">
      <div className="flex h-[6em] w-full flex-col items-center justify-center pb-4 pt-[1em]"></div>
      <div className="flex h-5/6 w-5/6 flex-col items-center space-y-8 rounded-3xl opacity-95 backdrop-blur-lg">
        <img src={lock} alt="lock" className="mt-20 h-8 w-8" />
        <div className="h-8 w-full">
          {failedAttempts > 0 && (
            <p className="text-5xl text-white">Attempt {failedAttempts} 😭</p>
          )}
        </div>
        <p className="text-2xl text-white">Guess the number:</p>
        <div className="mt-4 flex w-full flex-row space-x-2 px-4">
          <input
            className="grow rounded-none border-b-2 border-white bg-transparent text-center text-xl text-white focus:outline-none"
            type="password"
            inputMode="numeric"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                const correct = checkPassword(password, truePassword);
                if (!correct) {
                  setFailedAttempts(failedAttempts + 1);
                }
                setUnlock(correct);
              }
            }}
          />
          <button
            className="h-9 w-10 rounded border-2 border-white bg-transparent p-1 text-center text-md text-white opacity-90 focus:outline-none"
            onClick={() => {
              const correct = checkPassword(password, truePassword);
              if (!correct) {
                setFailedAttempts(failedAttempts + 1);
              }
              setUnlock(correct);
            }}
          >
            {"😳"}
          </button>
        </div>
      </div>
      <div className="flex h-[3em] w-full flex-col justify-center pb-4 pt-[1em]">
        <p className="header-text text-xl font-bold text-white"></p>
      </div>
    </div>
  );
}

function LockedByTime({ setLockedByTime }) {
  const [time, setTime] = React.useState(new Date());
  const [unlockDateTime] = React.useState(new Date("2024-01-13T20:00:00"));
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  });

  if (time >= unlockDateTime) {
    setLockedByTime(false);
  }
  const timeUntil = unlockDateTime - time;
  const hours = Math.floor(timeUntil / 1000 / 60 / 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeUntil / 1000 / 60) % 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((timeUntil / 1000) % 60)
    .toString()
    .padStart(2, "0");

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-center">
      <div className="flex h-1/2 flex-col items-center">
        <p className="header-text pt-20 text-4xl font-bold text-white">
          Happy 3 Months ❤️
        </p>
        <p className="header-text pt-4 text-4xl font-bold text-white">
          akong gugma
        </p>
        <div className="flex h-3/4 w-5/6 flex-col items-center space-y-2 rounded-3xl pt-10 opacity-95">
          <p className="time text-center text-4xl text-white">
            {hours}:{minutes}:{seconds}
          </p>
          <p className="w-full text-center text-2xl text-white">
            Till unlocked
          </p>
        </div>
        <div className="flex h-[3em] w-full flex-col justify-center pb-4 pt-[1em]"></div>
      </div>
    </div>
  );
}

function App() {
  const [lockedByTime, setLockedByTime] = React.useState(true);
  const [password, setPassword] = React.useState("");
  const [unlock, setUnlock] = React.useState(false);
  const [truePassword] = React.useState("153");
  useEffect(() => {
    document.body.style.zoom = "100%";
  }, []);

  return (
    <div className="background -z-50 flex h-full w-full flex-col items-center text-center">
      {unlock === false ? (
        <LockedByPassword
          password={password}
          setPassword={setPassword}
          setUnlock={setUnlock}
          truePassword={truePassword}
        />
      ) : lockedByTime && !DEVELOPMENT ? (
        <LockedByTime setLockedByTime={setLockedByTime} />
      ) : (
        <MainApp />
      )}
    </div>
  );
}

export default App;
